import React from "react"
import Contacts from "../components/Contacts"
import Head from "../components/Head"
import Footer from "../components/Footer"

class IndexPage extends React.Component {
  render() {
    return (
      <div>
        <Head />
        <div class="container">
          <div class="main">
            <div class="header">
              <div class="title">
                <a href="/" />
                <a href="../../../">
                  <br />
                </a>
                <a href="/">
                  <h1> </h1>
                </a>
              </div>
            </div>
            <div class="content">
              <div class="item">
                <h1>Herzlich willkommen!</h1>
                <br />
                <p class="MsoNormal">
                  Die besten Rezepte zum Backen, Kochen und Einwecken,
                  vorzugsweise aus der traditionellen siebenbürgischen Küche,
                  hat Katharina Gabber für Sie zusammengefasst! Machen Sie hier
                  eine
                  <a href="/leseprobe/"> Leseprobe</a> und bestellen Sie das
                  Kochbuch, Einweckbuch oder die Backbücher einfach direkt per
                  <a href="/bestellung/"> Online-Formular</a>.<br />
                </p>
              </div>
              <div class="item">
                <h1>Liebe Besucher</h1>
                <br />
                <p class="MsoNormal">
                  Wie in vielen siebenbürgischen Haushalten wurde auch bei uns
                  schon immer gut und reichlich gekocht und gebacken. Auf Wunsch
                  und mit Unterstützung meiner Kinder habe ich die im Laufe der
                  vielen Jahre die erprobten und gesammelten Rezepte geordnet
                  und auf Papier gebracht. Insgesamt ist daraus eine Auswahl an
                  leckeren Gerichten aus unserer siebenbürgischen Heimat
                  entstanden und wird von ausgesuchten Rezepten aus der neuen
                  Heimat ergänzt.
                </p>
                <p class="MsoNormal">
                  Zunächst entstand ein kleines, feines Backbuch mit 83
                  Rezepten. Die Nachfrage war unerhofft hoch und wir haben uns
                  darüber sehr gefreut. Später habe ich das Kochbuch mit 170
                  Rezepten veröffentlicht. <br />
                  Im ersten Backbuch fanden leider nicht alle Rezepte Platz. Ich
                  stellte fest,&nbsp; dass noch mehr Backrezepte, vor allem
                  viele, die wir in Siebenbürgen zubereiteten, es verdient haben
                  in einem Backbuch zusammengetragen zu werden. So entstand der
                  zweite Band meiner Backrezeptesammlung in dem ich erneut
                  köstliche siebenbürgische und deutsche Kuchen, Schnitten,
                  Torten und Plätzchen aufgeschrieben habe. <br />
                  Schließlich und endlich sollten die schmackhaften
                  Einweckrezepte nicht fehlen, so habe ich noch ein Einweckbuch
                  herausgebracht. <br />
                </p>
                <p class="MsoNormal">
                  Ich widme die Bücher vor allem meinen Kindern und
                  Enkelkindern, damit sie ihre Lieblingsgerichte selber machen
                  und auch weitergeben können, denn die meisten siebenbürgischen
                  Rezepte existieren nur noch im Gedächtnis. Es wäre sehr
                  schade, wenn die siebenbürgische Kochkultur in Vergessenheit
                  geriete. <br />
                </p>
                <p class="MsoNormal">
                  Zu jedem Rezept gibt es sowohl in den Backbüchern als auch im
                  Kochbuch und Einweckbuch ein Foto und eine genaue Anleitung
                  mit praktischen Tipps zum besseren Gelingen.
                </p>
                <p class="MsoNormal">
                  In diesem Sinne wünsche ich Ihnen viel Spaß beim Kochen, beim
                  Backen und beim Einwecken!
                </p>
                <p class="MsoNormal" align="right">
                  <strong>Katharina Gabber</strong>
                </p>
              </div>
            </div>
            <Contacts />
            <div class="clearer">
              <span />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default IndexPage
